import { Link, useNavigate } from "@remix-run/react";
import { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, HomeIcon, 
  ChatBubbleBottomCenterTextIcon, UserGroupIcon, LinkIcon,
  MegaphoneIcon, InformationCircleIcon, PuzzlePieceIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames'

interface MenuActive {
  active: boolean;
}

// https://tailwindui.com/components/application-ui/elements/dropdowns#component-1f10562f6266c9b3ef15ff792edd508e
export default function Navbar() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.addEventListener('keydown', function(keyboardEvent) {
      if(keyboardEvent.metaKey && keyboardEvent.key === 'k') {
        navigate(`/search`);
      }
    });
  }, []);

  const menuLinkClassNames = (active: boolean) => classNames(
    'px-4 py-2 text-sm flex items-center',
    active ? 'bg-gray-100 dark:bg-gray-700' : ''
  );

  return (
    // https://daisyui.com/components/navbar/
    // bg-base-100 dark:bg-slate-200
    <header className="navbar">
      <div className="flex-1 items-center">
        <Link to="/">
          <picture>
            <source media="(prefers-color-scheme: dark)" srcSet="/images/logos/repo_star_yellow_white.svg" />
            <source media="(prefers-color-scheme: light)" srcSet="/images/logos/repo_star_yellow.svg" />
            <img src="/images/logos/repo_star_yellow.svg" width="30" className="mr-1" alt="Related Repos logo" />
          </picture>        
        </Link>
        <Link to="/" className="font-semibold text-xl">Related Repos</Link>
      </div>
      <div className="flex-none">
        <Link to="/search">
          <div className="flex items-center mr-3 h-9 pr-2 pl-3 rounded-md background-button-white ring-1 ring-inset ring-gray-300">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
            {/* ⊞ */}
            <span className="ml-2 mr-1 flex items-center">
              <span className="text-rr-button-white-primary text-sm">Search</span>
              <span className="hidden lg:inline border dark:border-gray-400 rounded-md text-xs text-gray-400 p-0.5 ml-2">⌘K</span>
            </span>
          </div>
        </Link>

        <a className="hidden sm:block" href="https://github.com/RelatedRepos/discussion/discussions/new?category=general-feedback" target="_blank">
          <div className="flex items-center mr-3 h-9 pr-2 pl-3 rounded-md background-button-white ring-1 ring-inset ring-gray-300">
            <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2 text-gray-400" />
            {/* Or Users icon ? */}
            <span className="text-sm text-rr-button-white-primary">Feedback</span>
          </div>
        </a>

{/* Menu , -mr-1*/}
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button aria-label="App Dropdown Menu" className="inline-flex w-full justify-center gap-x-1.5 rounded-md background-button-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300">
              <Bars3Icon className=" h-5 w-5 text-gray-400" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            {/* remove this? focus:outline-none */}
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-gray-800  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <Link to="/" className={menuLinkClassNames(active)}>
                      <HomeIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Home</span>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <Link to="/search" className={classNames(menuLinkClassNames(active))}>
                      <MagnifyingGlassIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Search</span>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <Link to="/extension" className={classNames(menuLinkClassNames(active))}>
                      <PuzzlePieceIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Browser Extension</span>
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <Link to="/bookmarklet" className={classNames(menuLinkClassNames(active))}>
                      <LinkIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Bookmarklet</span>
                    </Link>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <a href="https://github.com/RelatedRepos/discussion/discussions/new?category=general-feedback" target="_blank" className={classNames(menuLinkClassNames(active))}>
                      <ChatBubbleBottomCenterTextIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Feedback</span>
                    </a>
                  )}
                </Menu.Item>
                {/* <Menu.Item>
                  {({ active }: MenuActive) => (
                    <a href="/blog" className={classNames(menuLinkClassNames(active))}>
                      <MegaphoneIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Blog</span>
                    </a>
                  )}
                </Menu.Item> */}
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <a href="https://github.com/RelatedRepos/discussion/discussions" target="_blank" className={classNames(menuLinkClassNames(active))}>
                      <UserGroupIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">Community</span>
                    </a>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }: MenuActive) => (
                    <Link to="/about" className={classNames(menuLinkClassNames(active))}>
                      <InformationCircleIcon className="h-5 w-5 mr-2 text-gray-400" />
                      <span className="text-rr-button-white-primary">About</span>
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  )
}