import { Link } from "@remix-run/react";

// https://tailwindui.com/components/marketing/sections/footers#component-9841e88626b7f5254528b3846f6ed3b6
export default function Index() {
  const yearNow = (new Date()).getFullYear();

  return (
    <footer>
      <div className="mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8 text-rr-light">
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5">
            &copy; {yearNow} Related Repos. All rights reserved.
          </p>
        </div>
        
        <div className="flex justify-center space-x-6 md:order-2">
          <div className="text-xs leading-5">
            <Link to="/privacy">Privacy</Link>
          </div>

          <div className="text-xs leading-5">
            <Link to="/terms">Terms</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}
